@use './util/themes.scss' as t;
@use './util/_mixins.scss' as m;
@use './util/_responsive.scss' as r;

#contact {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;

    h3{
        color: t.$accent2-dark;
        font-size: 25px;
    }
}

.card {
    width: 100%;
    max-width: 900px;
    padding: 20px;
    margin: 0 auto;
    background-color: t.$darker-bg-light;
    border-radius: 5px;
    overflow: hidden;

    @media (max-width: map-get(r.$breakpoints,md)) {
        max-width: 600px;
    }
}

.form {
    padding: 0 2rem 2rem 2rem;
    max-width: 500px;
    margin: 0 auto;
}

.contact_heading{
    @include m.sectionHeadings('contact');
}


.button {
    width: 100%;
    height: 3em;
    @include m.primaryButton;
}

@mixin input {
    display: block;
    width: 100%;
    height: 54px;
    border: 1px solid lighten(t.$text-light, 40%);
    border-radius: 5px;
    padding: 1ch;
    transition: 150ms ease;
    outline: none;
    font-size: 1rem;
    font-family: inherit;

    &:hover{
        border-color: t.$text-light;
    }
}

@mixin label {
    position: absolute;
    top: 1ch;
    left: 1ch;
    padding: 0 .5ch;
    pointer-events: none;
    transition: 150ms ease;
    color: #888;
}

@mixin label-focus {
    position: absolute;
    top: 0;
    font-size: 0.9em;
    font-weight: bold;
    color: t.$text-light;
    transform: translateY(-10%);
    font-weight: 600;
}

@mixin dropdown {
    appearance: none;
    width: 100%;
    border: 2px solid darken(t.$accent-dark, 20%);
    padding: 1ch 1ch 1ch 1.5ch;
    border-radius: 3px;
    transition: 150ms ease;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
    background: #fff url('data:image/svg+xml;utf8,<svg fill="gray" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') calc(100% - 1ch) center no-repeat;
}


.text-input {
    position: relative;
    margin-bottom: 1rem;
    label {
        @include label;
    }
    label.label-focus {
        @include label-focus;
    }
    input {
        @include input;
    }
}

.budget_dropdown{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    label{
        @include label;
    }

    &.focused label, select:not([value=""]) + label {
        @include label-focus;
    }

    select{
        @include dropdown();
    }
}

.text-area {
    position: relative;
    margin-bottom: 1rem;
    label {
        @include label;
    }
    label.label-focus {
        @include label-focus;
    }
    textarea {
        @include input;
        height: 8em;
    }
}