

// Dark Theme:
$primary-dark: #fbd962;
$secondary-dark: #636f3a;
$accent-dark: #efeef0;
$accent2-dark: #b17647;
$accent3-dark: #a7a4a2;
$background-dark: lighten(#020506, 7%);
// $background-dark: #020506;

// Light Theme:
$primary-light: #5F4DEE;
$secondary-light: #A5B7CC;
$text-light: #333333;
$darker-bg-light: #F3F7FD;
$background-light: #ffffff;


